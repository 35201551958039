<template>
  <EmSnackbar ref="snackbar" />
</template>
<script setup>
import { useNetwork } from '@vueuse/core'
const { isOnline, effectiveType } = useNetwork()
const snackbar = ref()
const { path } = useRouteParams()
const shouldHideSnackbar = computed(() => path.value.includes('/inbox'))
// TODO: add downlink to check network speed
watch([effectiveType, isOnline], (val) => {
  const [newEffectiveTypeValue, newOfflineValue] = val
  if (shouldHideSnackbar.value) { return }
  if (['3g', '2g'].includes(newEffectiveTypeValue)) {
    snackbar.value.show({ title: 'Your network speed is slow', text: 'Please check your internet connection.', type: 'warning', snackbarAction: null, icon: 'mdi-wifi-alert', canClose: true })
  } else if (!newOfflineValue) {
    snackbar.value.show({ title: 'You are offline', text: 'Please check your internet connection.', type: 'error', snackbarAction: null, icon: 'mdi-wifi-remove', canClose: true })
  } else {
    snackbar.value.close()
  }
})
</script>
