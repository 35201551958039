<template>
  <v-navigation-drawer
    v-bind="$attrs"
    id="navigationDrawer"
    v-model:model-value="open"
    app
    floating
    permanent
    :expand-on-hover="!drawerPinned"
    :rail="!drawerPinned"
    :rail-width="112"
    :width="280"
    mobile-breakpoint="0"
    color="primary-darken-1"
    data-cy="layout-default-drawer"
  >
    <div class="em-scrollbar height-100">
      <div class="min-height-100 d-flex flex-column px-2">
        <div
          class="d-flex align-center pt-4 px-3"
          :class="collapsed ? 'justify-center' : 'justify-space-between'"
        >
          <EmLogo :dense="collapsed" dark class="pa-1" data-cy="layout-default-logo" />
          <template v-if="!collapsed">
            <EmIconButton
              v-if="drawerPinned"
              icon="mdi-pin"
              data-cy-form-field="layout-default-pin"
              title="Unpin sidebar"
              color="white"
              @click="toggleLock(false)"
            />
            <EmIconButton
              v-else
              icon="mdi-pin-off"
              data-cy-form-field="layout-default-pin"
              title="Pin sidebar"
              color="white"
              @click="toggleLock(true)"
            />
          </template>
        </div>
        <EmNavigationDrawerMenus
          v-model="menusExpanded"
          :hide-expansion-icon="collapsed"
          @update:is-focusing-search="toggleLock(true)"
        />
        <EmNavigationDrawerFooter
          v-model="footerExpanded"
          :hide-expansion-icon="collapsed"
          data-cy="layout-default-footer"
        />
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script setup>
const open = ref(true)
// Expansion panels v-model is set to undefined when the panel is closed
const menusExpanded = ref(undefined)
const footerExpanded = ref(undefined)
watch(menusExpanded, (value) => {
  if (value === undefined) { return }
  footerExpanded.value = undefined
})
watch(footerExpanded, (value) => {
  if (value === undefined) { return }
  menusExpanded.value = undefined
})
const editablePreferences = usePrefencesStore().editable
const drawerPinned = editablePreferences.drawerPinned()
const toggleLock = (lock) => {
  drawerPinned.value = lock
}
const collapsed = ref(true)
const setCollapsed = (element) => {
  const COLLAPSED_WIDTH = 120
  collapsed.value = element[0]?.contentRect?.width < COLLAPSED_WIDTH
}
const resizeObserver = ref(null)
onMounted(() => {
  const navigationDrawer = document.getElementById('navigationDrawer')
  if (navigationDrawer) {
    resizeObserver.value = new ResizeObserver(setCollapsed)
    resizeObserver.value.observe(navigationDrawer)
  }
})
onUnmounted(() => {
  resizeObserver.value?.disconnect()
})
</script>
