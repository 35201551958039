<template>
  <v-navigation-drawer
    temporary
    class="fixed-safe-area--full"
    v-bind="$attrs"
    color="primary-darken-1"
    width="100%"
  >
    <div class="height-100 em-scrollbar">
      <div class="min-height-100 width-100 d-flex flex-column justify-space-between pt-4 px-3">
        <div class="d-flex justify-space-between mb-2">
          <EmLogo class="pl-5" dark />
          <EmIcon color="white" icon="mdi-close" @click="close" />
        </div>
        <EmNavigationDrawerMenus v-model="menusExpanded" is-mobile @click:menu="close" />
        <EmNavigationDrawerFooter v-model="footerExpanded" class="pb-4" is-mobile />
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script setup>
const menusExpanded = ref(undefined)
const footerExpanded = ref(undefined)
watch(menusExpanded, (value) => {
  if (value === undefined) { return }
  footerExpanded.value = undefined
})
watch(footerExpanded, (value) => {
  if (value === undefined) { return }
  menusExpanded.value = undefined
})
const emits = defineEmits(['update:modelValue'])
const close = () => emits('update:modelValue', false)
</script>
