<template>
  <template v-if="currentMessages.length > 0">
    <EmAlert
      v-for="message in currentMessages"
      :key="message.id"
      :type="message.severity"
      class="status-alert"
      icon="mdi-alert"
      position="top-center"
      :min-width="310"
    >
      <div class="d-flex flex-column">
        <EmBody1>
          {{ message.title }}
        </EmBody1>
        <EmBody2>
          {{ message.message }}
        </EmBody2>
      </div>
    </EmAlert>
  </template>
</template>
<script setup>
const { readable: userStoreReadable } = useUserStore()
const currentMessages = computed(() => {
  return userStoreReadable.messages
})
</script>
  <style lang="scss" scoped>
  .status-alert {
    position: absolute;
    top: 5%;
    left: 35%;
    z-index: 1;
  }
  </style>
