<template>
  <EmDialog
    v-model="showDialog"
    title="New version of Emissary"
    cancel-text="Try out later"
    :show-cancel="!release['disable-dismiss']"
    :can-close="!release['disable-dismiss']"
    confirm-text="Upgrade"
    :fullscreen="display.smAndDown"
    width="600"
    @close="dismissed=true"
    @confirm="reloadPageAndSetNewVersion"
  >
    <template #default>
      <div>
        <div class="d-flex">
          <v-card elevation="0" color="bgprimary" class="pa-6">
            <EmIcon
              color="primary"
              size="large"
              icon="mdi-rocket-launch"
            />
          </v-card>
          <div class="ml-4">
            <EmBody2>
              {{ release.message || DEFAULT_MESSAGE }}
            </EmBody2>
          </div>
        </div>
      </div>
    </template>
  </EmDialog>
</template>
<script setup>
import { useDisplay } from 'vuetify'
import { MS_PER } from '~/services/types/Time'
import { Service } from '@/services/helpers/ServiceHelper'
const display = ref(useDisplay())
const showDialog = ref(false)
const dismissed = ref(false)
const DEFAULT_MESSAGE = 'A new version of Emissary is available. When you\'re ready, reload to upgrade.'
const release = ref(
  {
    'current-version': '',
    message: '',
    'disable-dismiss': ''
  }
)
const checkReleaseInformation = async () => {
  const currentVersion = localStorage.getItem('current-version')
  const releaseInformation = await Service.api.releaseService.getReleaseInformation()
  const releaseVersion = releaseInformation['current-version']
  if (currentVersion !== releaseVersion && !dismissed.value && releaseVersion) {
    showDialog.value = true
  }
  release.value = releaseInformation
}
const reloadPageAndSetNewVersion = () => location.reload()
setInterval(checkReleaseInformation, MS_PER.Minute * 5)
onMounted(() => {
  Service.api.releaseService.getReleaseInformation().then((releaseInformation) => {
    localStorage.setItem('current-version', releaseInformation['current-version'])
  })
})
</script>
