<template>
  <div :class="{'pb-4': display.mdAndUp}">
    <v-expansion-panels v-model="expanded">
      <v-expansion-panel elevation="0" class="em-drawer-expansion-panel">
        <v-expansion-panel-title
          data-cy="layout-default-footer-button"
          :hide-actions="hideExpansionIcon"
          :class="hideExpansionIcon ? 'px-3 justify-center' : 'px-5'"
        >
          <div class="d-flex align-center">
            <EmLoadingSpinner
              v-if="user.readable.loadingUserInformation && !loggedUser.name"
              color="primary"
            />
            <div v-else :class="{ 'mr-3': !hideExpansionIcon }">
              <EmAvatar :name="loggedUser.name" small :phone-number="currentMenuPhoneNumber" />
            </div>
            <EmBody3
              v-show="!hideExpansionIcon"
              semi-bold
              data-cy="layout-default-footer-user"
              class="max-lines-1"
            >
              {{ loggedUser.name || 'unnamed' }}
            </EmBody3>
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text data-cy="layout-default-footer-usr-opts">
          <v-list class="pa-2">
            <v-expansion-panels v-model="expandedSubPanel">
              <v-expansion-panel class="em-drawer-expansion-panel" elevation="0">
                <v-expansion-panel-title class="px-4" :hide-actions="hideExpansionIcon">
                  <v-list-item
                    :data-cy="`layout-default-footer-${contactUsOption.eventName}`"
                    class="pa-0"
                    :class="{'justify-center': hideExpansionIcon}"
                  >
                    <template #prepend>
                      <div :class="{ 'mr-3': !hideExpansionIcon }">
                        <EmIcon :color="contactUsOption.color" :icon="contactUsOption.icon" />
                      </div>
                    </template>
                    <v-list-item-title v-show="!hideExpansionIcon">
                      <EmBody3 semi-bold>
                        {{ contactUsOption.title }}
                      </EmBody3>
                    </v-list-item-title>
                  </v-list-item>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <div class="px-2 pb-4">
                    <EmFormField
                      value="support@emissary.ai"
                      hide-details
                      :clearable="false"
                      readonly
                      class="custom-align-center"
                    >
                      <template #append-inner>
                        <EmCopySupportEmail>
                          <EmIconButton
                            icon="mdi-content-copy"
                            small
                            color="white"
                            title="Copy email"
                          />
                        </EmCopySupportEmail>
                      </template>
                    </EmFormField>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <EmNavigationDrawerSelectTenantDialog>
              <template #activator="activatorProps">
                <v-list-item
                  v-bind="activatorProps"
                  :class="{'justify-center': hideExpansionIcon}"
                  data-cy="layout-default-footer-switch-tenant"
                >
                  <template #prepend>
                    <div :class="{ 'mr-3': !hideExpansionIcon }">
                      <EmIcon color="white" icon="mdi-account-switch-outline" />
                    </div>
                  </template>
                  <v-list-item-title v-show="!hideExpansionIcon">
                    <EmBody3 semi-bold>
                      Switch account
                    </EmBody3>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </EmNavigationDrawerSelectTenantDialog>
            <v-list-item
              v-for="option in options"
              :key="option.eventName"
              :data-cy="`layout-default-footer-${option.eventName}`"
              :aria-label="`layout-default-footer-${option.eventName}`"
              :nuxt="!!option.to"
              :router="!!option.to"
              :to="option.to || null"
              :class="{'justify-center': hideExpansionIcon}"
              @click="processOption(option)"
            >
              <template #prepend>
                <div :class="{ 'mr-3': !hideExpansionIcon }">
                  <EmIcon :color="option.color" :icon="option.icon" />
                </div>
              </template>
              <v-list-item-title v-show="!hideExpansionIcon">
                <div class="d-flex align-center justify-space-between">
                  <EmBody3 semi-bold>
                    {{ option.title }}
                  </EmBody3>
                  <div v-if="option.rightIcon">
                    <EmIcon small :color="option.color" :icon="option.rightIcon" />
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script setup>
import { useDisplay } from 'vuetify'
const { $flushQueue } = useNuxtApp()
const display = ref(useDisplay())
const dialog = useDialog()
const user = useUserStore()
const props = defineProps({
  modelValue: { type: Number, default: undefined },
  isMobile: { type: Boolean, default: false },
  hideExpansionIcon: { type: Boolean, default: false }
})
const emits = defineEmits(['update:modelValue'])
const expanded = computed({
  get: () => props.modelValue,
  set: async (value) => {
    emits('update:modelValue', value)
    const firstPanelOpened = value === 0
    if (firstPanelOpened) {
      await $flushQueue(400)
      const logoutElement = document.querySelector('[aria-label="layout-default-footer-logout"]')
      if (logoutElement) {
        logoutElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }
})
const expandedSubPanel = ref()
const contactUsOption = ref({
  icon: 'mdi-face-agent',
  title: 'Contact us',
  eventName: 'copySupportEmail',
  hasAlert: false,
  color: 'white'
})
const options = ref([
  {
    icon: 'mdi-help-circle',
    rightIcon: 'mdi-open-in-new',
    title: 'Help',
    eventName: 'goToHelp',
    hasAlert: false,
    color: 'white'
  },
  {
    icon: 'mdi-cog',
    title: 'User preferences',
    eventName: 'userPreferences',
    hasAlert: false,
    color: 'white',
    to: '/user-preferences'
  },
  {
    icon: 'mdi-logout',
    title: 'Logout',
    eventName: 'logout',
    hasAlert: false,
    color: 'white'
  }
])
const loggedUser = computed(() => user.readable.userInfo || {})
const currentMenuPhoneNumber = computed(() => user.readable.phoneMenus && user.readable.phoneMenus[0] ? user.readable.phoneMenus[0]?.phone_number : '')
const { copyToClipboard } = useDeviceStore()
const copySupportEmail = async () => {
  await copyToClipboard({ value: 'support@emissary.ai', text: 'Support email' })
}
const goToHelp = () => window.open('https://help.emissary.ai/')
const showLogoutConfirmation = () => {
  dialog.info({
    text: 'Are you sure you would like to logout of Emissary on this device?',
    title: 'Logout',
    action: async () => { await navigateTo('/logout') },
    dialogProps: { confirmText: 'Logout', cancelText: 'Cancel' }
  })
}
const processOption = (option) => {
  if (option.to) {
    return
  }
  const actions = {
    goToHelp,
    copySupportEmail,
    logout: showLogoutConfirmation
  }
  actions[option.eventName]()
}
onBeforeMount(() => {
  user.getUserInformation()
})
</script>

<style lang="scss" scoped>
:deep(.custom-align-center .v-input__append-inner) {
  margin-top: 12px !important;
}
:deep(.custom-align-center .v-field__append-inner) {
  padding-top: 0% !important;
  align-items: center !important;
}
</style>
