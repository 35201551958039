<template>
  <EmAlert
    v-if="endpointStatus"
    :type="endpointStatus.severity"
    class="status-alert"
    icon="mdi-cloud-off-outline"
    position="top-center"
    :min-width="310"
  >
    <EmLoadingSpinner
      :text="endpointStatus.message"
      color="white"
      :size="18"
    />
  </EmAlert>
</template>
<script setup>
import { MS_PER } from '~/services/types/Time'
import { Service } from '@/services/helpers/ServiceHelper'
const endpointStatus = ref(null)
const getStatus = async () => {
  const status2Show = await Service.api.statusService.getEndpointStatus()
  if (status2Show) {
    endpointStatus.value = status2Show.message ? status2Show : null
  }
}
setInterval(getStatus, MS_PER.Minute * 2)
</script>
<style lang="scss" scoped>
.status-alert {
  position: absolute;
  top: 5%;
  left: 35%;
  z-index: 1;
}
</style>
