<template>
  <v-list-item class="pa-0" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <template #prepend>
      <div class="align-self-center" :class="hideExpansionIcon ? 'mr-2' : 'mr-3'">
        <EmSeatIcon v-if="phoneRoute.is_seat" color="white" />
        <EmShortPhoneNumberIcon v-else-if="isShortPhoneNumber(phoneRoute)" />
        <EmIconTooltip
          v-else-if="temporary"
          color="primary-lighten-5"
          :icon="!phoneRoute.enable_sms && phoneRoute.enable_whatsapp ? 'mdi-whatsapp' : 'mdi-phone'"
          :tooltip="`This menu is only visible when ${phone(phoneRoute.phone_number)} is active`"
        />
        <EmIcon
          v-else
          color="white"
          :size="hideExpansionIcon ? 'x-small' : 'default'"
          :icon="!phoneRoute.enable_sms && phoneRoute.enable_whatsapp? 'mdi-whatsapp' : 'mdi-phone'"
        />
      </div>
    </template>
    <div class="width-100 d-flex align-center">
      <v-list-item-title
        :class="temporary ? 'font-italic text-primary-lighten-5' : 'text-white'"
        class="flex-grow-1"
      >
        <EmBody3 semi-bold>
          <div
            class="format-first-digits text-overflow-ellipsis"
            :style="{lineHeight: 1.4}"
            :data-cy="`phone-menu-title-${phoneRoute.inbox_name?.replaceAll(' ', '-').toLowerCase()}`"
          >
            {{ phoneRoute.inbox_name }}
          </div>
        </EmBody3>
        <EmBody3 semi-bold>
          <div :class="['format-last-digits', {'collapsed': hideExpansionIcon}]" :style="{lineHeight: 1.4}">
            {{ fixDirection(phone(phoneRoute.phone_number)) }}
          </div>
        </EmBody3>
      </v-list-item-title>
      <v-slide-x-reverse-transition>
        <div v-show="isHover && !isMobile" class="pr-1">
          <EmCopyButton color="white" title="Phone number" :text2copy="phoneRoute.phone_number" />
        </div>
      </v-slide-x-reverse-transition>
    </div>
  </v-list-item>
</template>

<script setup>
import { useDisplay } from 'vuetify'
const { phone, fixDirection } = useFilters()
defineProps({
  phoneRoute: {
    type: Object,
    required: true
  },
  hideExpansionIcon: {
    type: Boolean,
    required: true
  },
  temporary: {
    type: Boolean,
    default: false
  }
})
const display = ref(useDisplay())
const isHover = ref(false)
const isMobile = computed(() => display.value.smAndDown)
const isShortPhoneNumber = phoneMenu => phoneMenu.phone_number?.replace('+', '')?.length <= 6
const onMouseEnter = () => {
  isHover.value = true
}
const onMouseLeave = () => {
  isHover.value = false
}
</script>
