<template>
  <EmDialog
    v-model="showDialog"
    :title="callStarted ? 'Call in progress' : 'Someone is calling you'"
    width="400"
  >
    <template #default>
      <InboxConversationHeaderContactInfo
        v-if="caller"
        :phone-number="caller"
        data-cy="call-incomming-contact"
      />
    </template>
    <template #buttons>
      <CallButtons v-if="callStarted" @end-call="endIncomingCall" />
      <div v-else class="width-100 d-flex justify-space-between align-center">
        <EmIconButton
          title="Reject Call"
          icon="mdi-phone-hangup"
          size="large"
          color="white"
          button-class="bg-error"
          @click="endIncomingCall"
        />
        <EmIconButton
          title="Accept Call"
          icon="mdi-phone"
          size="large"
          color="white"
          button-class="bg-success"
          @click="acceptIncomingCall"
        />
      </div>
    </template>
  </EmDialog>
</template>

<script setup>
import { Service } from '@/services/helpers/ServiceHelper'
const showDialog = ref(false)
const callStarted = ref(false)
const caller = ref(null)
const acceptIncomingCall = () => {
  Service.api.callService.acceptIncomingCall()
}
const endIncomingCall = () => {
  Service.api.callService.endIncomingCall()
}
onMounted(async () => {
  await Service.api.callService.listenToIncomingCalls({
    onIncoming: (from) => {
      caller.value = from
      showDialog.value = true
    },
    onStart: () => {
      callStarted.value = true
    },
    onEnd: () => {
      showDialog.value = false
      callStarted.value = false
      caller.value = null
    }
  })
})
</script>
