<template>
  <EmLoadingBar v-if="loadingMenus" :loading="loadingMenus" />
  <div v-else class="overflow-hidden flex-grow-1 d-flex flex-column">
    <!-- Search // TODO: It would be nice to have this inside a component -->
    <div
      class="d-flex align-center pa-3"
      :class="hideExpansionIcon ? 'justify-center' : 'justify-space-between'"
    >
      <v-fade-transition mode="out-in">
        <div v-if="showInboxSearch" class="flex-grow-1">
          <EmFormField
            ref="inboxSearchField"
            v-model="inboxSearch"
            autofocus
            placeholder="Search Inboxes"
            hide-details
            @focus="setFocus(true)"
            @blur="changeBlur"
          />
        </div>
        <div v-else class="d-flex align-center py-2">
          <EmAccountIcon color="white" class="mr-05" @click="cleanCacheAndReload" />
          <EmBody2 v-if="!hideExpansionIcon" semi-bold class="max-lines-1">
            {{ currentTenantName }}
          </EmBody2>
        </div>
      </v-fade-transition>
      <EmIconButton
        v-if="!hideExpansionIcon"
        ref="toggleSearchButton"
        title="Search inboxes"
        icon="mdi-magnify"
        color="white"
        class="ml-2"
        @click="toggleSearch(!showInboxSearch)"
      />
    </div>
    <!-- All Inboxes Button -->
    <EmNavigationDrawerMenusList
      v-if="phoneRoutes.length > MAX_SHOWABLE_PHONE_ROUTES"
      :routes="[allInboxesRoute]"
      :hide-expansion-icon="hideExpansionIcon"
    />
    <!-- No inboxes -->
    <div v-if="showInboxSearch && showablePhoneRoutes.length === 0" class="py-3 px-5 d-flex align-center">
      <EmBody2>
        No inboxes found.
      </EmBody2>
    </div>
    <!-- Inboxes // TODO: It would be nice to have this inside a component -->
    <div v-else class="em-inboxes">
      <div class="em-inboxes__shadow em-inboxes__shadow--top" />
      <div class="em-inboxes__scrollbar em-scrollbar">
        <v-expansion-panels v-model="expanded" :mandatory="!!activePhoneRoute">
          <v-expansion-panel
            v-for="(phoneRoute, index) in showablePhoneRoutes"
            :key="phoneRoute.phone"
            :data-cy="`phone-menu-${index}`"
            :aria-label="`phone-menu-${index}`"
            elevation="0"
            class="em-drawer-expansion-panel"
          >
            <v-expansion-panel-title
              :hide-actions="hideExpansionIcon"
              :class="hideExpansionIcon ? 'px-3' : 'px-5'"
              @click="desktopNavigateTo(phoneRoute.menus[0].to)"
            >
              <EmNavigationDrawerPhoneHeader
                :phone-route="phoneRoute"
                :hide-expansion-icon="hideExpansionIcon"
              />
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-list class="pa-2">
                <v-list-item
                  v-for="route in phoneRoute.menus"
                  :key="route.title"
                  :data-cy="`phone-menu-${index}-${route.title.replaceAll(' ', '-')}`"
                  :aria-label="`phone-menu-${index}-${route.title.toLowerCase()}`"
                  router
                  nuxt
                  :to="route.to"
                  :class="{ 'justify-center': hideExpansionIcon }"
                  @click="$emit('click:menu', route.title)"
                >
                  <template #prepend>
                    <div :class="{ 'mr-3': !hideExpansionIcon }">
                      <v-badge
                        :model-value="route.hasNotification"
                        color="error"
                        bordered
                        overlap
                        dot
                      >
                        <EmIcon color="white" :icon="route.icon" />
                      </v-badge>
                    </div>
                  </template>
                  <v-list-item-title>
                    <EmBody2 v-show="!hideExpansionIcon">
                      {{ route.title }}
                    </EmBody2>
                    <v-chip v-if="route.notificationsAmount" color="error">
                      {{ route.notificationsAmount }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="em-inboxes__shadow em-inboxes__shadow--bottom" />
    </div>
    <!-- Others -->
    <div class="py-4 flex-grow-1 d-flex flex-column justify-end">
      <EmNavigationDrawerMenusList :routes="globalRoutes" :hide-expansion-icon="hideExpansionIcon" />
    </div>
  </div>
</template>

<script setup>
const { $flushQueue } = useNuxtApp()
const { onlyLettersAndNumbers } = useFilters()
const { path } = useRouteParams()
const { globalRoutes, currentTenant } = useUserMenuOptions()
const user = useUserStore()
const props = defineProps({
  modelValue: { type: Number, default: undefined },
  hideExpansionIcon: { type: Boolean, default: false },
  isFocusingSearch: { type: Boolean, default: false },
  isMobile: { type: Boolean, default: false }
})
const emits = defineEmits(['click:menu', 'update:isFocusingSearch', 'update:modelValue'])
const currentTenantName = computed(() => currentTenant.value?.name || '')
const loadingMenus = computed(() => user.readable.loadingMenus)
const phoneRoutes = computed(() => user.readable?.phoneMenus || [])
const allInboxesRoute = computed(() => ({
  to: '/inbox/all',
  title: `All ${phoneRoutes.value.length} Inboxes`,
  icon: 'mdi-inbox-multiple',
  description: 'Here you can find all available inboxes.',
  hasNotification: false
}))
const inboxSearch = ref(null)
const activePhoneRoute = computed(() => user.activePhoneMenuFromPath(path.value))
const MAX_SHOWABLE_PHONE_ROUTES = ref(10)
const showablePhoneRoutes = computed(() => {
  const search = onlyLettersAndNumbers(inboxSearch.value)
  if (!search) { return phoneRoutes.value }
  const filterBySearch = inbox => onlyLettersAndNumbers(inbox.phone_number)?.includes(search) ||
    onlyLettersAndNumbers(inbox.inbox_name)?.includes(search)
  return phoneRoutes.value.filter(filterBySearch)
  // TODO: Figure out what are we going to do with the sidebar phoneRoutes limit
  // const filteredPhoneRoutes = phoneRoutes.value
  //   .filter(filterBySearch)
  //   .slice(0, MAX_SHOWABLE_PHONE_ROUTES.value)
  // if (search || !activePhoneRoute.value) { return filteredPhoneRoutes }
  // const isActivePhoneRouteFiltered = !!filteredPhoneRoutes.find(inbox => inbox.id === activePhoneRoute.value?.id)
  // return isActivePhoneRouteFiltered ? filteredPhoneRoutes : [...filteredPhoneRoutes, activePhoneRoute.value]
})
const expanded = computed({
  get: () => props.modelValue,
  set: value => emits('update:modelValue', value)
})
const expandActivePhoneRoute = async () => {
  await nextTick()
  expanded.value = showablePhoneRoutes.value.indexOf(activePhoneRoute.value)
  await $flushQueue(400)
  const firstPhoneRouteElement = document.querySelector('[aria-label="phone-menu-0"]')
  const activePhoneRouteElement = document.querySelector(`[aria-label="phone-menu-${expanded.value}"]`)
  if (activePhoneRouteElement) {
    activePhoneRouteElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  } else if (firstPhoneRouteElement) {
    firstPhoneRouteElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}
watch(() => [activePhoneRoute.value, showablePhoneRoutes.value], expandActivePhoneRoute)
const toggleSearchButton = ref()
const showInboxSearch = ref(false)
const setFocus = (value) => {
  emits('update:isFocusingSearch', !!value)
}
const changeBlur = (ev) => {
  setFocus(false)
  const shouldIgnoreClickOutside = ev.relatedTarget !== toggleSearchButton.value.getButton().$el
  if (shouldIgnoreClickOutside) {
    showInboxSearch.value = !!inboxSearch.value
  }
}
const toggleSearch = (value) => {
  if (!value) {
    inboxSearch.value = ''
  }
  showInboxSearch.value = value
}
const cleanCacheAndReload = () => {
  user.refreshUserCache()
}
const desktopNavigateTo = (routeToGo) => {
  if (!props.isMobile) {
    navigateTo(routeToGo)
  }
}
</script>

<style lang="scss" scoped>
.em-inboxes {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 230px;
  max-height: calc(var(--window-height) * 0.5);
  &__scrollbar {
    padding: 30px 0;
  }
  &__shadow {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    height: 30px;
    &--top {
      top: 0;
      background: linear-gradient(rgb(var(--v-theme-primary-darken-1)) 0%, transparent 100%);
    }
    &--bottom {
      bottom: 0;
      background: linear-gradient(transparent 0%, rgb(var(--v-theme-primary-darken-1)) 100%);
    }
  }
}
</style>
