<template>
  <v-app>
    <v-app-bar
      v-if="display.smAndDown"
      clipped-left
      flat
      app
      class="fixed-safe-area--top neutral--border-bottom border--lighten-4 white z-index-2"
      height="60"
    >
      <div class="d-flex justify-space-between width-100 py-6 px-3">
        <div>
          <EmLogo />
        </div>
        <div>
          <EmIcon
            aria-label="layout-drawer-btn"
            data-cy="layout-drawer-btn"
            icon="mdi-menu"
            @click="showMobileDrawer()"
          />
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <EmStatusAlert />
      <EmMessageAlert />
      <NetworkStatusBanner />
      <EmReleaseDialog v-if="!isMobileDevice" />
      <CallIncomingDialog v-if="user.canUseVoiceCalls" />
      <EmNavigationDrawer v-model="isMobileDrawerOpenned" />
      <NuxtPage />
      <EmCookieScript />
    </v-main>
  </v-app>
</template>
<script setup>
import { useDisplay } from 'vuetify'
import useDialog from '@/composables/useDialog'
const dialog = useDialog()
const display = ref(useDisplay())
const user = useUserStore()
const { readable: deviceStoreReadable } = useDeviceStore()
defineComponent({
  name: 'Default'
})
const isMobileDrawerOpenned = ref(false)
const isMobileDevice = computed(() => deviceStoreReadable.devicePrefs.isMobileDevice)
const showMobileDrawer = () => {
  isMobileDrawerOpenned.value = false
  nextTick(() => {
    isMobileDrawerOpenned.value = true
  })
}
onMounted(() => {
  if (!user) {
    dialog.warning({
      text: 'There was an unexpected problem while loading the user services. Please click the button reload to load them again',
      title: 'Oops',
      action: () => { location.reload() },
      dialogProps: {
        confirmText: 'Reload',
        canClose: false,
        showCancel: false
      }
    })
    return
  }
  user.getUserServices()
})
</script>
